
import { useEffect } from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';

import routes from './pages';
import Page404 from './pages/404';

import '../src/assets/binasea.css';
import '../src/assets/font-awesome.css';
import React from 'react';

function App() {

	// React.useEffect(() => {
	// 	//Initialize Web3 Window Listeners
	// 	if (!web3Initialized) {
	// 		if (window.ethereum.on !== undefined) {
	// 			window.ethereum.on("chainChanged", app.web3.setChainId);
	// 			window.ethereum.on("accountsChanged", app.web3.setAccounts);
	// 			app.web3.initializeWeb3().then(() => {
	// 				setWeb3Initialized(true);
	// 			});
	// 		}
	// 	}
	// }, [web3Initialized]);

	useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  return (
    <>
      <Header />
      <Routes>
        {
          routes.map((data, idx) => (
            <Route key={idx} path={data.path} element={data.component} />
          ))
        }
        <Route path='*' element={<Page404 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;



import img1 from '../images/slider/slider-product-1.jpg'
import img2 from '../images/slider/slider-product-2.jpg'
import img3 from '../images/slider/slider-product-3.jpg'
import img4 from '../images/slider/slider-product-4.jpg'
import img5 from '../images/slider/slider-product-5.jpg'

const dataBanner2 = [
    {
        id: 1,
        
        heading: 'Definddb2, Collect and Sell Super Rate NFT ',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        img: img1,
    },
    {
        id: 2,
        
        heading: 'Defind, Collect and Sell Super Rate NFT ',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        img: img2,
    },
    {
        id: 3,
        
        heading: 'Defind, Collect and Sell Super Rate NFT ',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        img: img3,
    },
    {
        id: 4,
        
        heading: 'Defind, Collect and Sell Super Rate NFT ',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        img: img4,
    },
    {
        id: 5,
        
        heading: 'Defind, Collect and Sell Super Rate NFT ',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        img: img5,
    },
    

]

export default dataBanner2;
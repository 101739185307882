

import img1 from '../images/svg/icon-create-1.svg'
import img2 from '../images/svg/icon-create-2.svg'
import img3 from '../images/svg/icon-create-3.svg'
import img4 from '../images/svg/icon-create-4.svg'

const dataCreate = [
    {
        id: 1,
        img: img1,
		link: 'https://docs.strategysportsnetwork.com',
        heading: 'DAO Documentation',
        text: 'Review the SSN documentation to learn more about the DAO and how governance works.'
    },
    {
        id: 2,
        img: img2,
		link: 'https://vote.strategysportsnetwork.com',
        heading: 'DAO Governance',
        text: 'The DAO is governed by the community in a decentralized way. Members may submit proposals and vote on changes.'
    },
    {
        id: 3,
        img: img3,
		link: '/about',
        heading: 'Learn More',
        text: 'Learn more about how and why the DAO was created, along with its mission and vision.'
    },
    {
        id: 4,
        img: img4,
		link: '/contact',
        heading: 'Communicate with the DAO',
        text: 'Use the DAOs immutable messaging system to send messages to the DAO and read all previous messages to and from the DAO.'
    },


]

export default dataCreate;
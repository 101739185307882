import React from 'react';
import dataBanner from '../assets/fake-data/data-banner';
import dataCreate from '../assets/fake-data/data-create';
import Banner01 from '../components/banner/Banner01';
import Create from '../components/create/Create';

function Home01() {
    return (
        <div className="home-1">
            <div id='page'>
                <Banner01 data={dataBanner} />
                <Create data={dataCreate} />
            </div>
        </div>

    );
}

export default Home01;

import { makeAutoObservable } from "mobx";
import { FirebaseStore } from "./firebase/firebaseStore";
import { UserStore } from "./user/userStore";
import { Web3Store } from "./web3/web3Store";

class AppState {
  fb: FirebaseStore;
  user: UserStore;
  web3: Web3Store;


  constructor() {
    makeAutoObservable(this);
    this.fb = new FirebaseStore();
    this.user = new UserStore();
    this.web3 = new Web3Store();
  }
}

const app = new AppState();
export default app;

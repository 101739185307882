const menus = [
  {
    id: 1,
    name: "Docs",
    target: "_blank",
    links: "https://docs.strategysportsnetwork.com/",
  },
  {
    id: 2,
    name: "Governance",
    target: "_blank",
    links: "https://vote.strategysportsnetwork.com/",
  },
  {
    id: 3,
    name: "About",
    target: "_self",
    links: "/about",
  },
  {
    id: 4,
    name: "Contact",
    target: "_self",
    links: "/contact",
  },
  {
    id: 5,
    name: "Claim",
    target: "_self",
    links: "/claim",
  },
];

export default menus;
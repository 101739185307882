import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo/logo-ssn-header-dark.png";
import logodark from "../../assets/images/logo/logo-ssn-header.png";

import "./styles.scss";

function Footer() {

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <footer className="footer">
      <div className="tf-container">
        <div className="row">
          <div className="col-xl-4 col-lg-3 col-md-3">
            <div className="widget widget-infor">
              <div className="logo">
                <img id="logo_footer" className="logo-dark" src={logodark} alt="Strategy Sports Network DAO" />
                <img id="logo_footer" className="logo-light" src={logo} alt="Strategy Sports Network DAO" />
              </div>
              <p className="content small">
                Strategy Sports Network is not represented by any specific parties. Only approved messages, voted on by
                the members should be considered official DAO communications.
              </p>
              <ul className="social-item">
                <li>
                  <Link to="https://twitter.com/SSNetworkDAO" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://github.com/strategy-sports-network-dao" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-github"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://github.com/strategy-sports-network-dao" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-telegram-plane"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://github.com/strategy-sports-network-dao" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://github.com/strategy-sports-network-dao" target="_blank" rel="noopener noreferrer">
                    <i className="icon-fl-tik-tok-2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://github.com/strategy-sports-network-dao" target="_blank" rel="noopener noreferrer">
                    <i className="icon-fl-vt"></i>
                  </Link>
                </li>
              </ul>
              <p className="copy-right small">Copyright ©2024 Strategy Sports Network LLC DAO. All Rights Reserved.</p>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 col-md-5">
            <div className="widget widget-menu">
              <div className="menu menu-1">
                <h6 className="widget-title">Resources</h6>
                <ul>
                  <li>
                    <Link to="https://docs.strategysportsnetwork.com">Docs</Link>
                  </li>
                  <li>
                    <Link to="/https://vote.strategysportsnetwork.com">Governance</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-4">
    
          </div>
        </div>
      </div>

      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </footer>
  );
}

export default Footer;

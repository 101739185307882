// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, computed, makeAutoObservable } from 'mobx';
import { ethers } from 'ethers';
import app from '..';

export interface IWeb3Store {
  chainId: string;
  network: string;
  accounts: string[];
  balance: string;
  defaultRPC: string;
  showModal: boolean;
}

export class Web3Store implements IWeb3Store {
  chainId = '';
  network = '';
  defaultChainIdHex = '0x13881'
  defaultRPC = 'https://polygon-rpc.com';
  accounts = [];
  ethersProvider = this._getProvider();
  balance = "0";
  daoGovernanceToken = "";
  showModal = false;
  ssnTokenAddress = "0xb7660Dcc31356D67316a0380Eeea67a11CE37658";
  

  constructor() {
    makeAutoObservable(this);
  }

  _getProvider(): ethers.JsonRpcProvider | ethers.BrowserProvider {
    if (window.ethereum !== undefined) {
      return new ethers.BrowserProvider(window.ethereum, 'any');
    } else {
      return new ethers.JsonRpcProvider(this.defaultRPC);
    }
  }

  @computed convertEthToWEI = (ethToConvert: string): ethers.BigNumberish => {
    return ethers.parseEther(ethToConvert);
  }
  @computed isConnected = (): boolean => {
    return this.accounts.length > 0;
  }
  @computed convertWEIToETH = (weiToConvert: string): string => {
    return ethers.formatUnits(weiToConvert);
  }

  @action setShowModal = (showModal: boolean): void => { 
    this.showModal = showModal;
  }

  @action initializeWeb3 = async (): Promise<void> => {
    window.ethereum.autoRefreshOnNetworkChange = false;
    await this.setNetworkDetails();
  }
  @action resetUser = (): void => {
    this.accounts = [];
  }
  @action setChainId = async (): Promise<void> => {
    try {
      this.chainId = await window.ethereum.request({
        method: 'eth_chainId',
      });
      this.network = getNetworkName(this.chainId);
      if (this.isPolygonMainnetNetwork()) {
        this.setShowModal(false);
      } else {
        this.setShowModal(true);
      }
    } catch (err) {
      console.error(err);
    }


  }
  @action setNetworkDetails = async (): Promise<void> => {
    await this.setAccounts();
    await this.setChainId();
    // await this.setCurrentBalance();
  }

  @action setAccounts = async (): Promise<void> => {
    const tempAccounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    this.accounts = tempAccounts;
    if (tempAccounts && tempAccounts.length > 0 && app.user.address !== tempAccounts[0]) {
      app.user.setUser(tempAccounts[0]);
    }
  }

  @action addChainPolygonMainnet = async (): Promise<void> => {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x89',
          rpcUrls: ["https://polygon-rpc.com"],
          chainName: 'Polygon-Mainnet',
          nativeCurrency: { name: 'Matic', decimals: 18, symbol: 'MATIC' },
          blockExplorerUrls: ['https://polygonscan.com/'],
        },
      ],
    });
  }

  @action openPolygonModal = (): void => {
    if (!this.isPolygonMainnetNetwork()) {
      this.setShowModal(true);
    }
  }

  @computed isPolygonMainnetNetwork = (): boolean => {
    return this.chainId === "0x89";
  }




  signPersonalMessage = async (signingAddress: string, messageToSign: string): Promise<string> => {
    const msg = `0x${Buffer.from(messageToSign, 'utf8').toString('hex')}`;
    const signature = await window.ethereum.request({
      method: 'personal_sign',
      params: [msg, signingAddress, 'SongbirdNFTS'],
    });
    return signature;
  }
	getCurrentNetworkName =():string=>{
		return getNetworkName(this.chainId);
	}
	@action addChainSongbird = async (): Promise<void> => {
		await window.ethereum.request({
			method: 'wallet_addEthereumChain',
			params: [
				{
					chainId: '0x13',
					rpcUrls: ['https://songbird-api.flare.network/ext/C/rpc'],
					chainName: 'Songbird',
					nativeCurrency: { name: 'Songbird', decimals: 18, symbol: 'SGB' },
					blockExplorerUrls: ['https://songbird-explorer.flare.network/'],
				},
			],
		});
	}
}

export const web3Store = new Web3Store();



const getNetworkName = (chainId: string) => {
  switch (chainId) {
    case '1':
      return 'Ethereum - MainNet';
    case '3':
      return 'Ethereum - Ropsten';
    case '14':
      return 'Flare';
	case '0x13':
		return 'Songbird';
    case '42':
      return 'Ethereum - Kovan';
    case '4':
      return 'Ethereum - Rinkeby';
    case '97':
      return 'BSC - Testnet';
    case '56':
      return 'BSC - MainNet';
    case '0x89':
      return 'Polygon - MainNet';
    case '80001':
      return 'Polygon - Mumbai';
    default:
      return '';
  }

};


import React, { useEffect, useState } from 'react';

import AA from "../../assets/images/logo/aarealx-logo.jpg";

import { Link } from "react-router-dom";
import app from '../../@state';
import { ethers } from 'ethers';
import { getNFTClaimStatus } from '../../api/claims/getNFTClaimStatus';
import { commaDelimitedStringToIntArray } from '../../utils/convertStringToIntList';

const AAClaimComponent = (): JSX.Element => {
	const tokenContractAddress = '0x030c7d8872c0c0c8b0ff888ace4f4cb3e4e37e3b';
	const claimContractAddress = '0x960119098b9bFd6201Ac24d866bf6FDD6198616B';
	const [aaSgbBalance, setAASgbBalance] = useState(0);
	const [refresh, setRefresh] = useState(false);
	const [isPending, setIsPending] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [isClaimable, setIsClaimable] = useState(false);
	const [isApproved, setIsApproved] = useState(false);
	const initTokenIds: number[] = [];
	const [tokenIds, setTokenIds] = useState(initTokenIds);

	useEffect(() => {
		const loadStatus = async () => {
			//await app.web3.setAccounts();
			const userStatus = await getNFTClaimStatus(claimContractAddress, app.user.address);
			const balance = userStatus[0];
			const claimable = userStatus[1];
			const isApproved = userStatus[2];
			setAASgbBalance(Number(balance));
			setIsClaimable(claimable);
			setIsApproved(isApproved);
		}
		loadStatus();
	}, [refresh]);

	const handleApproveClicked = async () => {
		if (app.web3.getCurrentNetworkName() !== "Songbird") {
			await app.web3.addChainSongbird();
		}
		setIsPending(true);
		const abi = ['function setApprovalForAll(address operator, bool approved)'];
		const provider = new ethers.BrowserProvider(window.ethereum, 'any');
		const contract = new ethers.Contract(tokenContractAddress, abi, await provider.getSigner());
		const tx = await contract.setApprovalForAll(claimContractAddress, true);
		await tx.wait();
		setIsPending(false);
		setRefresh(!refresh);
	}

	const getClaimButton = () => {
		if (isComplete) {
			return <button className="tf-button style-2 disable full-btn" disabled>Claim Complete</button>
		} else if (isPending) {
			return <button className="tf-button style-2 disable full-btn" disabled>Tx Pending...</button>
		} else if (isClaimable && isApproved) {
			return <button className="tf-button style-2 full-btn" onClick={handleClaim}>Claim</button>
		} else if (isClaimable && !isApproved) {
			return <button className="tf-button style-2 full-btn" onClick={handleApproveClicked}>Approve</button>
		} else if (!isClaimable && aaSgbBalance > 0) {
			return <button className="tf-button style-2 disable" disabled>Claims Not Open</button>
		} else {
			return <button className="tf-button style-2 disable full-btn" disabled>No NFTs Found</button>
		}
	}


	const handleClaim = async () => {
		try {
			if (!(app.web3.getCurrentNetworkName() === 'Songbird')) {
				await app.web3.addChainSongbird();
			}
			if (tokenIds.length > 0) {
				setIsPending(true);
				const abi = ['function requestClaim(uint256[] memory _tokenIds)'];
				const provider = new ethers.BrowserProvider(window.ethereum, 'any');
				const contract = new ethers.Contract(claimContractAddress, abi, provider);
				const tx = await contract.requestClaim(tokenIds);
				await tx.wait();
				setIsPending(false);
				setIsComplete(true);
			}
		} catch (error) {
			console.log("Error ", error);
		}
	}

	return (

		<div className="tf-create">
			<div className="round-img pb-3 ">
				<img src={AA} alt="AA SGB" />
			</div>

			<h6 className="title">
				<Link to="#">
					Anonymous Astronauts Songbird - <span>{aaSgbBalance.toLocaleString()}</span>
				</Link>
			</h6>
			<p className="content">
				This is an NFT collection on the Songbird network. Anonymous Astronauts Songbird Holders will be
				entitled to claim their proportional share of 3M SSN Governance Tokens.
			</p>
			<p className="content small">*Note Claiming Governance Tokens Will Burn Your NFTs</p>
			<div className="row pt-5">
				<div className="col-md-7">
					<input onChange={(e) => setTokenIds(commaDelimitedStringToIntArray(e.target.value))} placeholder={"Token Ids to Claim..."}></input>
				</div>
				<div className="col-md-5">
					{getClaimButton()}
				</div>
			</div>
		</div>
	);
};
export default AAClaimComponent;

import { getFunctions, httpsCallable } from "firebase/functions";

export const uploadTextToIpfs = async (text: string): Promise<string> => {
  const functions = getFunctions();
  const uploadTextToIPFS = httpsCallable(functions, 'uploadTextToIPFS');
  return uploadTextToIPFS({ data: text })
    .then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      console.log("result", result);
      const data = result.data as string;
      console.log("data", data);
      return data;
    }).catch((error) => {
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      const details = error.details;
      // ...
      console.log("error", code, message, details);
      return "";
    });
}
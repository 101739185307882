import { ethers } from "ethers";
import app from "../../@state";

const abi = ['function getUserStatus(address _user) view returns (uint256, bool, bool)'];

export const getNFTPStatus = async (userAddress: string): Promise<any[]> => {
	if(userAddress.length === 0){
		if(window.ethereum){
			await app.web3.setAccounts();
		}
	}
	const claimContractAddress = '0xAAff44Db5Aa1b3BC388973cC159265c16329cCd0';
	if (claimContractAddress.length > 0) {
		try {
			const sgbProvider = new ethers.JsonRpcProvider("https://songbird-api.flare.network/ext/C/rpc");
			const contract = new ethers.Contract(claimContractAddress, abi, sgbProvider);
			const [balance, claimable, hasClaimed] = await contract.getUserStatus(userAddress);
			return [balance, claimable, hasClaimed];
		} catch (error) {
			console.log('Error checkIfNFTPHasBeenClaimed', error);
			return [0, false, false];
		}
	}
	return [0, false, false];
}
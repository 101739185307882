// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, computed, makeAutoObservable } from "mobx";

export interface IUserStore {
  address: string;
  balance: number;
}

export class UserStore implements IUserStore {
  address = "";
  balance = 0;

  constructor() {
    makeAutoObservable(this);
  }

  @computed userIsConnected = (): boolean => {
   return this.address.length > 0;
  };

  @action setUser = (userAddress: string): void => {
    this.address = userAddress;
    this.setBalance("0");
  };

  @action setBalance = (balance: string): void => {
    this.balance = parseFloat(balance);
  };



}

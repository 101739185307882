import { ethers } from "ethers";


const abi = ['function getUserStatus(address _user) view returns (uint256, bool, bool)'];

export const getNFTClaimStatus = async (claimContractAddress: string, userAddress: string) => {
	// if (app.web3.getCurrentNetworkName() !== "Songbird") {
	// 	await app.web3.addChainSongbird();
	// }
	if (claimContractAddress.length > 0) {
		try {
			const sgbProvider = new ethers.JsonRpcProvider("https://songbird-api.flare.network/ext/C/rpc");
			const contract = new ethers.Contract(claimContractAddress, abi, sgbProvider);
			const result = await contract.getUserStatus(userAddress);
			return result;
		} catch (error) {
			console.log('Error Check NFT Claim Status', error);
			return false;
		}
	}
	return false;
}
//import img1 from "../images/slider/slider-1.jpg";
import img1 from "../images/slider/dao3.jpg";
import avt1 from "../images/svg/noun-sports.svg";
//import avt1 from "../images/slider/ball-1.png";

const dataBanner = [
  {
    id: 1,

    heading: "Decentralized, Autonomous, Permanent!",
    desc: "Strategy Sports Network was created by a group of sports fans looking to combine sports and crypto in new innovative ways!",
    img: img1,
    price: "Pull up a chair!",
    avt: avt1,
    name: "”The Best Games!”",
    tag: "",
  },
];

export default dataBanner;

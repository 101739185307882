import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NFTpTicker from "../../assets/images/logo/ticker-NFTP.png";
import app from "../../@state";

import { ethers } from "ethers";
import { getNFTPStatus } from "../../api/claims/getNFTPStatus";
const NFTPClaimComponent = (): JSX.Element => {
	const [nftpBalance, setNftpBalance] = useState(0);
	const [nftpClaimed, setNFTPClaimed] = useState(false);
	const [isPending, setIsPending] = useState(false);
	const [isClaimable, setIsClaimable] = useState(false);

	useEffect(() => {
		const loadNFTPStatus = async () => {
			const result = await getNFTPStatus(app.user.address);
			const balance = Number(ethers.formatEther(result[0]));
			//const isClaimable = result[1];
			const hasClaimed = result[2];
			setNFTPClaimed(hasClaimed);
			setIsClaimable(false);
			//setIsClaimable(isClaimable);
			setNftpBalance(balance);
		}
		loadNFTPStatus();
	}, [isClaimable]);

	const getNFTPClaimButton = () => {
		if (isPending) {
			return <button className="tf-button style-2 disable" disabled>Claim Pending</button>
		} else if (nftpClaimed) {
			return <button className="tf-button style-2 disable" disabled>Already Claimed</button>
		} else if (isClaimable) {
			return <button className="tf-button style-2" onClick={handleClaimNFTp}>Claim</button>
		} else if (!isClaimable && nftpBalance > 0) {
			return <button className="tf-button style-2 disable" disabled>Claims Not Open</button>
		} else {
			return <button className="tf-button style-2 disable" disabled>No NFTp Found</button>
		}
	}



	const handleClaimNFTp = async () => {
		const claimAddress = '0xAAff44Db5Aa1b3BC388973cC159265c16329cCd0';
		try {
			if (!(app.web3.getCurrentNetworkName() === 'Songbird')) {
				await app.web3.addChainSongbird();
			}
			setIsPending(true);
			const abi = ['function requestClaim()'];
			const provider = new ethers.BrowserProvider(window.ethereum, 'any');
			const contract = new ethers.Contract(claimAddress, abi, await provider.getSigner());
			const tx = await contract.requestClaim();
			await tx.wait();
			setIsPending(false);
			setNFTPClaimed(true);
		} catch (error) {
			console.log("Error ", error);
		}
	}

	return (
		<div className="tf-create">
			<img width="120px" className="pt-3 pb-4" src={NFTpTicker} alt="NFTP Claim" />
			<h6 className="title">
				<Link to="#"> NFTp - <span>{nftpBalance.toLocaleString()}</span></Link>
			</h6>
			<p className="content">This was a reward token claimable on Songbird for delegates of Best FTSO. NFTP Holders will be entitled to claim their proportional share of 4M  SSN Governance Tokens.</p>
			<div className="row pt-5">
				<div className="col-lg-12">

					{getNFTPClaimButton()}  </div>
			</div>
		</div>
	);
};
export default NFTPClaimComponent;
import React, { useState } from "react";
import icon1 from "../assets/images/svg/noun-mission.svg";
import icon2 from "../assets/images/svg/noun-ticket.svg";
import icon3 from "../assets/images/svg/noun-american-football-player.svg";
import icon4 from "../assets/images/svg/noun-transparency.svg";
import icon5 from "../assets/images/svg/noun-inclusivity.svg";
import icon6 from "../assets/images/svg/noun-innovation.svg";
import icon7 from "../assets/images/svg/noun-join.svg";
import icon8 from "../assets/images/svg/discord.svg";
import { Link } from "react-router-dom";

function AboutPage() {
  const [dataWallet] = useState([
    {
      id: 1,
      img: icon1,
      cate: "none",
      title: "Mission",
      text: "Strategy Sports Network DAO envisions a world where the excitement and passion for sports extend beyond the game, creating a vibrant community for fans and collectors. By leveraging the power of blockchain, we aim to introduce a new era of sports memorabilia—one that is more accessible, transparent, and engaging than ever before.",
    },
    {
      id: 2,
      img: icon2,
      cate: "none",
      title: "Revolutionize the Sports Collectible Industry",
      text: "We are committed to redefining what sports collectibles can be, making them not just collectibles, but also tokens of fan engagement and athlete support. Through blockchain, we ensure authenticity, rarity, and connection between athletes and fans.",
    },
    {
      id: 3,
      img: icon3,
      cate: "none",
      title: "Support College Athletes",
      text: "Strategy Sports Network DAO believes in the power and potential of collegiate athletes. We strive to provide them with opportunities to benefit from their Name, Image, and Likeness (NIL), empowering them to build their brand and secure their future while still in school. ",
    },
     {
      id: 4,
      img: icon7,
      cate: "none",
      title: "Join Us",
      text: "Are you a sports fan, collector, or athlete looking to be part of a community that shares your passion and values? Strategy Sports Network DAO is more than an organization—it's a movement towards a more connected and rewarding sports culture. Join us as we build the future of sports collectibles and athlete support together.",
    },
    {
      id: 5,
      img: icon5,
      cate: "none",
      title: "Inclusivity",
      text: "We welcome sports fans, collectors, and athletes from all walks of life to join our community and contribute to our mission.",
    },
    {
      id: 6,
      img: icon6,
      cate: "none",
      title: "Innovation",
      text: "We continuously explore new technologies and strategies to enhance the value and experience of sports collectibles and NIL deals.",
    },
    {
      id: 7,
      img: icon4,
      cate: "none",
      title: "Transparency",
      text: "Every transaction and decision within the DAO is recorded on the blockchain, ensuring fairness and accountability.",
    },
    {
      id: 8,
      img: icon8,
      cate: "none",
      title: "Discord",
      text: "For more information on how to become a member, participate in our governance, or learn about our current projects, visit our platform or reach out to us directly. Let's redefine the game, together.",
    },
  ]);
  return (
    <div className="about-page">
      <section className="tf-page-title">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Pages</Link>
                </li>
                <li>About</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-connect-wallet">
        <div className="tf-container">
          <div className="row height100 justify-content-center">
            <div className="col-md-12">
              <div className="tf-heading style-5">
                <h4 className="heading">What is Strategy Sports Network DAO?</h4>
                <p className="sub-heading">
                  Welcome to Strategy Sports Network DAO, a pioneering decentralized organization at the intersection of sports fandom, blockchain innovation, and collegiate athletics support. Founded by a passionate group of sports enthusiasts, our mission is to transform the sports collectible landscape and forge meaningful connections between fans and college athletes through Name, Image, and Likeness (NIL) deals.{" "}
                </p>
              </div>
            </div>
            {dataWallet.map(idx => (
              <div key={idx.id} className="col-lg-3 col-md-6">
                <div className="tf-create">
                  <div className="icon">
                    <img src={idx.img} alt="Strategy Sports Network DAO" />
                    <span className={`label ${idx.cate}`}>BETA</span>
                  </div>
                  <h6 className="title">
                    <Link to="#">{idx.title}</Link>
                  </h6>
                  <p className="content">{idx.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutPage;
import { ethers } from "ethers";
import app from "../@state";


export const getEthersProvider = (): ethers.BrowserProvider | ethers.JsonRpcProvider => { 
  if (window.ethereum) {
	  if (app.web3.network !== "Polygon") {
		  return new ethers.JsonRpcProvider('https://polygon-rpc.com');
	}else{
		return new ethers.BrowserProvider(window.ethereum, 'any');
	}
  } else { 
    return new ethers.JsonRpcProvider('https://polygon-rpc.com');
  }
};
import { makeAutoObservable } from "mobx";
import { FirebaseApp, initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { getFirestore, Firestore, doc, getDoc } from 'firebase/firestore/lite';
import "firebase/firestore";

export interface IFirebaseStore {
  app: FirebaseApp;
  firebaseAppDefined: boolean;
  db: Firestore;
}

export class FirebaseStore implements IFirebaseStore {
  app = initializeApp(firebaseConfig);
  db = getFirestore(this.app);
  firebaseAppDefined = false;

  constructor() {
    makeAutoObservable(this);
  }

  setFirebaseAppDefined = () => {
    if (this.app)
      this.firebaseAppDefined = true;
  }

  async test(): Promise<void> {
    const docRef = doc(this.db, "test", "test");
    const docResult = await getDoc(docRef);
    console.log("test", docResult.data());  
  }

}

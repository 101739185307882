import React from "react";
import { Link } from "react-router-dom";
import AAClaimComponent from "../components/claim-components/AAClaimComponent";
import BoredApesClaimComponent from "../components/claim-components/BoredApesClaimComponent";
import NFTPClaimComponent from "../components/claim-components/NFTPClaimComponent";

function ClaimPage() {
  return (
    <div className="claim-page">
      <section className="tf-page-title">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Pages</Link>
                </li>
                <li>About</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-connect-wallet">
        <div className="tf-container">
          <div className="row height100">
            <div className="col-md-12">
              <div className="tf-heading style-5">
                <h4 className="heading">Are you Eligible to Claim?</h4>
                <p className="sub-heading">
                  If you hold any of the following tokens from the Songbird Network, you're eligible!{" "}
                </p>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6">
              <NFTPClaimComponent />
            </div>
            <div className="col-xl-4 col-lg-6"> <BoredApesClaimComponent /></div>
            <div className="col-xl-4 col-lg-12"><AAClaimComponent /></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ClaimPage;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { getEthersProvider } from "../utils/getEthersProvider";
import { ethers } from "ethers";
import app from "../@state";
import { uploadTextToIpfs } from "../utils/uploadTextToIpfs";

function Contact() {
  const [incomingMessageCount, setIncomingMessageCount] = useState<number>(0);
  const [incomingMessageId, setIncomingMessageId] = useState<number>(0);
  const [incomingMessageUri, setIncomingMessageUri] = useState<string>("");
  const [outgoingMessageCount, setOutgoingMessageCount] = useState<number>(0);
  const [outgoingMessageId, setOutgoingMessageId] = useState<number>(0);
  const [outgoingMessageUri, setOutgoingMessageUri] = useState<string>("");
  const [newMessageUri, setNewMessageUri] = useState<string>("");
  const [newMessage, setNewMessage] = useState<string>("");
  const [messageSent, setMessageSent] = useState<boolean>(false);


  const handleIncomingMessageIdChanged = async (messageId: number) => {
    if (typeof messageId === "number" && messageId > 0 && messageId <= incomingMessageCount) {
      setIncomingMessageId(messageId);
      await loadIncomingMessageUri(messageId);
    } else {
      setIncomingMessageId(0);
      setIncomingMessageUri("");
    }
  }

  const handleOutgoingMessageIdChanged = async (messageId: number) => {
    if (typeof messageId === "number" && messageId > 0 && messageId <= outgoingMessageCount) {
      setOutgoingMessageId(messageId);
      await loadOutgoingMessageUri(messageId);
    } else {
      setOutgoingMessageId(0);
      setOutgoingMessageUri("");
    }
  }

  const getIncomingMessageViewButton = (): JSX.Element => {
    if (incomingMessageUri.length > 0) {
      return <a href={`https://ipfs.io/ipfs/${incomingMessageUri}`} target="_blank" className="tf-button style-2 full-btn" rel="noreferrer">
        View
     </a>
    } else {
      return <> </>;
    }
  }
  const getOutgoingMessageViewButton = (): JSX.Element => {
    if (outgoingMessageUri.length > 0) {
      return <a href={`https://ipfs.io/ipfs/${outgoingMessageUri}`} target="_blank" className="tf-button style-2 full-btn" rel="noreferrer"> 
        View
      </a>
    } else {
      return <> </>;
    }
  }

  const loadOutgoingMessageUri = async (messageId: number) => {
    const provider = getEthersProvider();
    const abi = ['function getOutgoingMessageUri(uint256 _messageId) view returns (string)'];
    const contract = new ethers.Contract(app.web3.ssnTokenAddress, abi, provider);
    const uri = await contract.getOutgoingMessageUri(messageId);
    setOutgoingMessageUri(uri);
  }

  const loadIncomingMessageUri = async (messageId: number) => {
    const provider = getEthersProvider();
    const abi = ['function getIncomingMessageUri(uint256 _messageId) view returns (string)'];
    const contract = new ethers.Contract(app.web3.ssnTokenAddress, abi, provider);
    const uri = await contract.getIncomingMessageUri(messageId);
    setIncomingMessageUri(uri);
  }


  const loadIncomingMessageCount = async () => {
    const provider = getEthersProvider();
    const abi = ['function incomingCounter() view returns (uint256)'];
    const contract = new ethers.Contract(app.web3.ssnTokenAddress, abi, provider);
    const tx = await contract.incomingCounter();
    const count = ethers.getNumber(tx);
    setIncomingMessageCount(count);
  }
  const loadOutgoingMessageCount = async () => {
    const provider = getEthersProvider();
    const abi = ['function outgoingCounter() view returns (uint256)'];
    const contract = new ethers.Contract(app.web3.ssnTokenAddress, abi, provider);
    const tx = await contract.outgoingCounter();
    const count = ethers.getNumber(tx);
    setOutgoingMessageCount(count);
  }

  const handleNewMessageSent = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const uri = await uploadTextToIpfs(newMessage);
    setNewMessageUri(uri);
    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum, 'any');
      const abi = ['function sendIncomingMessageToDAO(string _messageUri)'];
      const contract = new ethers.Contract(app.web3.ssnTokenAddress, abi, await provider.getSigner());
      const tx = await contract.sendIncomingMessageToDAO(uri);
      console.log("tx", tx);
      setMessageSent(true);
      setNewMessage("");
    }
  }


  useEffect(() => {
    loadIncomingMessageCount();
    loadOutgoingMessageCount();
  }, []);
  return (
    <div>
      <section className="tf-page-title style-2">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Contact</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-contact">
        <div className="tf-container">
          <div className="row ">
            <div className="col-md-6">
              <div className="content">
                <h2 className="title-detail">DAO Message History</h2>
                <Tabs className="tf-tab">
                  <TabList className="menu-tab">
                    <Tab className="tab-title ">
                      <Link to="#" tabIndex={1}>
                        DAO Messages
                      </Link>
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className="tab-details">
                      <div className="top">
                        <div className="author">
                          <div className="infor">
                            <i className="fas fa-thumbtack"></i> 
                            <h6 className="name">Total Outgoing Messages From DAO - {outgoingMessageCount}</h6>
                            <div className="tab-create-item">
                              <div className="set-message-id">
                                <fieldset>
                                  <input type="text" placeholder="Id" value={outgoingMessageId} onChange={(e) => handleOutgoingMessageIdChanged(Number(e.target.value))} />
                                </fieldset>
                              </div>
                            </div>
                            <div className="tab-create-item">
                              <div className="set-message-id">
                                <div className="btn-submit">
                                  {getOutgoingMessageViewButton()}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="infor">
                            <i className="fas fa-inbox"></i> 
                            <h6 className="name">Total Incoming Messages To DAO - {incomingMessageCount}</h6>
                            <div className="tab-create-item">
                              <div className="set-message-id">
                                <fieldset>
                                  <input type="text" placeholder="Id" value={incomingMessageId} onChange={(e) => handleIncomingMessageIdChanged(Number(e.target.value))} />
                                </fieldset>
                              </div>
                            </div>
                            <div className="tab-create-item">
                              <div className="set-message-id">
                                <div className="btn-submit">
                                  {getIncomingMessageViewButton()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tf-heading style-3">
                <h4 className="heading">Send Us An Immutable Message</h4>
                <p className="sub-heading">
                  The DAO has created a smart contract that allows anyone to send message to the DAO via IPFS. Complete
                  the form below and sign the transaction to send your message.
                </p>
              </div>
              <form id="commentform" className="comment-form">
                <fieldset className="message">
                  <textarea
                    value={newMessage}
                    id="message"
                    name="message"
                    rows={10}
                    placeholder="Enter your message here..."
                    tabIndex={4}
                    aria-required="true"
                    required={true}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                </fieldset>
                <div className="btn-submit">
                  {newMessage.length >= 25 && !messageSent ? <button className="tf-button" onClick={(e) => handleNewMessageSent(e)}>
                    Send message
                  </button> : <></>}
                  {messageSent ? <a href={`https://ipfs.io/ipfs/${newMessageUri}`} target='_blank' rel="noreferrer"><button className="tf-button">
                    View message
                  </button></a> : <></>}


                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;

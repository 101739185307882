

import img1 from '../images/blog/blog-1.jpg'
import img2 from '../images/blog/blog-2.jpg'
import img3 from '../images/blog/blog-3.jpg'
import img4 from '../images/blog/blog-4.jpg'
const dataBlog = [
    {
        id: 1,
        img: img1,
        title: 'The Next Big Trend In Crypto',
        cate: 'Music NFT’s',
        user: 'By SalvadorDali',
        time: 'Feb 19, 2021',
        text :'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters...'
    },
    {
        id: 2,
        img: img2,
        title: 'Decentraland And Meta Verse',
        cate: 'Music NFT’s',
        user: 'By SalvadorDali',
        time: 'Feb 19, 2021',
        text :'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters...'
    },
    {
        id: 3,
        img: img3,
        title: 'Beeple Everydays The First 10 Days',
        cate: 'Music NFT’s',
        user: 'By SalvadorDali',
        time: 'Feb 19, 2021',
        text :'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters...'
    },
    {
        id: 4,
        img: img4,
        title: 'Videos Releases Car Drive',
        cate: 'Music NFT’s',
        user: 'By SalvadorDali',
        time: 'Feb 19, 2021',
        text :'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters...'
    },


   
]

export default dataBlog;
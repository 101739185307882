import React from "react";
import PropTypes from "prop-types";

import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import img1 from "../../assets/images/slider/bg-slider.png";
import img3 from "../../assets/images/slider/ball-1.png";
import discordIcon from "../../assets/images/svg/discord.svg";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import "./styles.scss";
import { Link } from "react-router-dom";

Banner01.propTypes = {
  data: PropTypes.array,
};

function Banner01(props) {
  const { data } = props;

  return (
    <section className="tf-slider">
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <Swiper
              modules={[Navigation, Scrollbar, A11y]}
              spaceBetween={0}
              slidesPerView={1}
              className="slider-home home1"
              loop={true}
            >
              {data.slice(0, 2).map(idx => (
                <SwiperSlide key={idx.id}>
                  <div className="tf-slider-item">
                    <div className="content-inner">
                      <h1 className="heading">
                        Decentrali<span>zed,</span> Autonomous, Permanent!
                        <img src={img1} className="img-effect" alt="Strategy Sports Network DAO" />
                      </h1>
                      <p className="sub-heading">{idx.desc}</p>
                      <div className="btn-slider ">
                        <Link to="/about" className="tf-button style-2">
                          About
                        </Link>
                        <Link to="https://docs.strategysportsnetwork.com" className="tf-button style-3">
                          Docs
                        </Link>
                      </div>
                    </div>
                    <div className="image">
                      <div className="img-slider-main ani5">
                        <img src={idx.img} alt="Strategy Sports Network DAO" />
                      </div>
                      <img src={img3} alt="Strategy Sports Network DAO" className="img-slider-2 ani7" />
                      <img src={img3} alt="Strategy Sports Network DAO" className="img-slider-3 ani6" />

                      <div className="current-bid ">
                        <div className="title">Strategy Session ?</div>
                        <div className="price">
                          {" "}
                          <img
                            src={discordIcon}
                            className="discord-strategy"
                            width="25"
                            height="25"
                            alt="Strategy Sports Network DAO"
                          />{" "}
                          {idx.price}
                        </div>
                      </div>
                      <div className="card-infor ani4">
                        <img src={idx.avt} width="60" height="60" alt="Strategy Sports Network DAO" />
                        <div className="inner ">
                          <h6 className="name">{idx.name}</h6>
                          <p className="author">{idx.tag}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner01;
